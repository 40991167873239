// TwoColumnSection.js
import React from "react";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  AdjustmentsHorizontalIcon,
  PlayCircleIcon,
  ChevronDownIcon
} from "@heroicons/react/24/outline";

function TwoColumnSection() {
  return (
    <div className="bg-gray-900 text-white py-24 px-4 relative">
      <div className="max-w-7xl mx-auto">
        {/* Top Arrow Icon */}

        <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-30 w-full flex justify-center">
          <ChevronDownIcon className="h-20 w-20 text-blue-500 transform scale-x-150" />
        </div>

        {/* Two Column Layout */}
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Left Column */}
          <div className="space-y-6">
            <div style={{display: 'flex', alignItems: 'center', }}>
                <AdjustmentsHorizontalIcon aria-hidden="true" className="size-20 text-white" />
                <h2 className="text-3xl font-bold">Mixing</h2>
            </div>
            <p className="text-gray-300">
              We follow a structured approach to bring your ideas to life. From
              concept to execution, our team is here every step of the way.
            </p>
            <ul className="text-gray-400 space-y-2">
              <li>✔ Detailed Planning</li>
              <li>✔ High-Quality Design</li>
              <li>✔ Thorough Testing</li>
              <li>✔ Seamless Delivery</li>
            </ul>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <div style={{display: 'flex', alignItems: 'center', }}>
                <PlayCircleIcon aria-hidden="true" className="size-20 text-white" />
                <h2 className="text-3xl font-bold">Mastering</h2>
            </div>

            <p className="text-gray-300">
              We offer a variety of services to meet the needs of our clients,
              from custom software solutions to in-depth consulting.
            </p>
            <ul className="text-gray-400 space-y-2">
              <li>✔ Custom Software Development</li>
              <li>✔ UX/UI Design</li>
              <li>✔ IT Consulting</li>
              <li>✔ Ongoing Support</li>
            </ul>
          </div>
        </div>

        {/* Bottom Arrow Icon */}
        <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 translate-y-1/2 opacity-30">
          <ChevronDownIcon className="h-20 w-20 text-blue-500 transform scale-x-150" />
        </div>
      </div>
    </div>
  );
}

export default TwoColumnSection;
