import {
    AdjustmentsHorizontalIcon,
    PlayCircleIcon
} from "@heroicons/react/24/outline";

export default function ServiceSection() {
  return (
    <div>
      <div>

      </div>
      <div>
      
      </div>
    </div>
  );
}
