// Pricing.js
import React from 'react';

function Pricing() {
  return (
    <div className="text-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold sm:text-4xl">Our Pricing Plans</h2>
          <p className="text-gray-400 mt-2">
            Choose a plan that suits your needs.
          </p>
        </div>

        <div className="grid gap-8 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
          {/* Basic Plan */}
          <div className="bg-gray-800 rounded-lg shadow-lg p-8">
            <h3 className="text-2xl font-semibold">Basic</h3>
            <p className="mt-4 text-gray-400">Ideal for individuals starting out</p>
            <p className="mt-8 text-4xl font-extrabold">$10<span className="text-xl font-medium">/mo</span></p>
            <ul className="mt-6 space-y-4 text-gray-400">
              <li>5 Projects</li>
              <li>10GB Storage</li>
              <li>Email Support</li>
            </ul>
            <a
              href="#"
              className="mt-8 inline-block w-full py-3 px-4 text-center text-white font-semibold bg-blue-500 rounded-lg hover:bg-blue-600 transition"
            >
              Get Started
            </a>
          </div>

          {/* Pro Plan */}
          <div className="bg-gray-700 rounded-lg shadow-lg p-8 border border-blue-500">
            <h3 className="text-2xl font-semibold">Pro</h3>
            <p className="mt-4 text-gray-400">Best for growing teams and businesses</p>
            <p className="mt-8 text-4xl font-extrabold">$30<span className="text-xl font-medium">/mo</span></p>
            <ul className="mt-6 space-y-4 text-gray-400">
              <li>50 Projects</li>
              <li>100GB Storage</li>
              <li>Priority Email Support</li>
            </ul>
            <a
              href="#"
              className="mt-8 inline-block w-full py-3 px-4 text-center text-white font-semibold bg-blue-500 rounded-lg hover:bg-blue-600 transition"
            >
              Choose Pro
            </a>
          </div>

          {/* Enterprise Plan */}
          <div className="bg-gray-800 rounded-lg shadow-lg p-8">
            <h3 className="text-2xl font-semibold">Enterprise</h3>
            <p className="mt-4 text-gray-400">For large organizations and enterprises</p>
            <p className="mt-8 text-4xl font-extrabold">Custom</p>
            <ul className="mt-6 space-y-4 text-gray-400">
              <li>Unlimited Projects</li>
              <li>1TB Storage</li>
              <li>24/7 Support</li>
              <li>Dedicated Account Manager</li>
            </ul>
            <a
              href="#"
              className="mt-8 inline-block w-full py-3 px-4 text-center text-white font-semibold bg-blue-500 rounded-lg hover:bg-blue-600 transition"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
