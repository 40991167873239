
export default class AudioManager {

    audioReferences = [];

    registerAudio(stopPlayingCallback) {
        console.log(stopPlayingCallback)
        this.audioReferences.push(stopPlayingCallback);
    }

    requestControl() {
        for(let audioReference of this.audioReferences) {
            if(audioReference !== null) {
                audioReference();
            }
        }
    }
}