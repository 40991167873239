import React, { useEffect, useRef, useState } from "react";
import { PlayIcon, PauseIcon } from "@heroicons/react/24/solid";

function Audio(props) {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMattPriceMix, setIsMattPriceMix] = useState(false);
  const [progress, setProgress] = useState(0);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      props.audioManager.requestControl();
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const stopPlaying = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  useEffect(() => {
    props.audioManager.registerAudio(stopPlaying);
  }, []);

  const updateProgress = () => {
    const currentTime = audioRef.current.currentTime;
    const duration = audioRef.current.duration;

    if ((currentTime / duration) * 100 > 50) {
      setIsMattPriceMix(true);
    } else {
      setIsMattPriceMix(false);
    }

    setProgress((currentTime / duration) * 100);
  };

  const seekAudio = (event) => {
    const width = event.target.clientWidth;
    const clickX = event.nativeEvent.offsetX;
    const duration = audioRef.current.duration;
    audioRef.current.currentTime = (clickX / width) * duration;
  };

  return (
    <div className="bg-gray-900 text-white py-16 px-4">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4">{props.name}</h2>

        <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
          {/* Audio Player Controls */}
          <div className="flex items-center justify-center space-x-4 mb-4">
            <button
              onClick={togglePlayPause}
              className="bg-blue-600 p-2 rounded-full hover:bg-blue-700"
            >
              {isPlaying ? (
                <PauseIcon className="h-8 w-8 text-white" />
              ) : (
                <PlayIcon className="h-8 w-8 text-white" />
              )}
            </button>
            <span className="text-gray-400 text-sm">
              {isPlaying ? "Playing" : "Paused"}
            </span>

            {isMattPriceMix ? (
              <span
                className={`inline-block px-4 py-1 text-sm font-semibold text-white bg-green-500 rounded-full`}
              >
                Matt Price Mix
              </span>
            ) : (
              <span
                className={`inline-block px-4 py-1 text-sm font-semibold text-white bg-red-500 rounded-full`}
              >
                It's Fucking Raw
              </span>
            )}
          </div>

          {/* Progress Bar */}
          <div
            className="relative w-full h-2 bg-gray-600 rounded-full cursor-pointer"
            onClick={seekAudio}
          >
            <div
              className="absolute top-0 left-0 h-full bg-blue-500 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>

        {/* Hidden Audio Element */}
        <audio
          ref={audioRef}
          onTimeUpdate={updateProgress}
          onEnded={() => setIsPlaying(false)}
          src={props.song}
        />
      </div>
    </div>
  );
}

export default Audio;
