import logo from "./logo.svg";
import "./App.css";
import FeatureSection from "./Example";
import ServiceSection from "./Services";
import TwoColumnSection from "./Arrow";
import Pricing from "./Pricing";
import Audio from "./Audio";
import AudioManager from "./AudioManager";

function App() {

  const audioManager = new AudioManager();

  return (
    <div className="bg-black text-white">
      <div className="h-screen flex flex-col items-center justify-center text-center p-6">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Welcome to Matt Price Productions
          </h1>
          <p className="text-lg md:text-xl text-gray-300 mb-8">
            Experience the sounds of da future
          </p>
          <a
            href="#"
            className="inline-block px-6 py-3 text-lg font-semibold rounded-lg bg-blue-500 hover:bg-blue-600 transition duration-300"
          >
            Get Started
          </a>
        </div>
      </div>
      <TwoColumnSection />
      <ServiceSection />
      <FeatureSection />
      <Pricing />
      <Audio
        audioManager={audioManager}
        name="Hello World 1"
        song="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3"
      />
      <Audio
        audioManager={audioManager}
        name="Hello World 2"
        song="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3"
      />
      <Audio
        audioManager={audioManager}
        name="Hello World 3"
        song="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3"
      />
      <Audio
        audioManager={audioManager}
        name="Hello World 4"
        song="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3"
      />
    </div>
  );
}

export default App;
